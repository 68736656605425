import { DeepPartial } from "react-hook-form";

import { SubmitSection } from "@/components/domains/checkout-form/SubmitSection";
import { PointPlanValues } from "@/components/domains/yearplan/PointPlanForm/schema";
import { PointProduct } from "@/generated/open-api/schemas";
import { PaymentMethod } from "@/models/payment/consts";
import { PointProductName } from "@/models/pointProduct/type";
import { numberFormat } from "@/utils";
import { formatCurrency } from "@/utils/currency";

import styles from "./PointPlanConfirmation.module.scss";

interface PointPlanConfirmationProps {
  form: DeepPartial<PointPlanValues>;
  pointProduct: PointProduct;
  onClickBack: () => void;
  onClickSubmit: () => void;
}

export function PointPlanConfirmation({
  form,
  pointProduct,
  onClickSubmit,
  onClickBack,
}: PointPlanConfirmationProps): React.ReactNode {
  return (
    <div className={styles.container}>
      <div className="text__center">
        <h2 className="text__xl text__bold text__center text___yellow mg__bottom__m">
          ご注文内容の確認
        </h2>
      </div>
      <p className="text__m text__bold text__yellow">商品名</p>
      <p className="text__m mg__bottom__m">{form.planType && PointProductName[form.planType]}</p>
      <p className="text__m text__bold text__yellow">獲得ポイント数量</p>
      <p className="text__m mg__bottom__m">
        {numberFormat(pointProduct.point)}ポイント
        <br />
        ※本日より2年間有効
      </p>
      <p className="text__m text__bold text__yellow">決済方法</p>
      {form.paymentData?.paymentMethod === PaymentMethod.credit && (
        <p className="text__m mg__bottom__m">
          クレジットカード支払い
          <br />
          **** **** **** {form.paymentData.last4}
        </p>
      )}
      <p className="text__m text__bold text__yellow">お支払い金額</p>
      <p className="text__m mg__bottom__m">{formatCurrency(pointProduct.price)}</p>
      <SubmitSection onClickBack={onClickBack} onClickSubmit={onClickSubmit} />
    </div>
  );
}

import * as Sentry from "@sentry/nextjs";
import { isAxiosError } from "axios";

// Sentryにエラーを送信する
export const sendError = (error: unknown): string | null => {
  if (isAxiosError(error)) {
    // API通信の場合500以降のエラーのみ送信
    if (error.status && error.status >= 500) {
      return Sentry.captureException(error);
    }

    return null;
  }

  return Sentry.captureException(error);
};

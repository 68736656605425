"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import { usePathname, useSearchParams } from "next/navigation";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Button, LoadingOverlay, PanelSelector, useAmazonPay } from "@/components";
import { ConfigButton } from "@/components/domains/checkout-form/CheckoutForm/ConfigButton/ConfigButton";
import { PaymentMethodSelector } from "@/components/domains/checkout-form/PaymentMethodSelector";
import { useGetPointProductSuspense } from "@/generated/open-api/customer/customer";
import { PaymentMethod } from "@/models/payment/consts";
import { PointProductName, PointProductPlan } from "@/models/pointProduct/type";
import { fireAmazonPayAction } from "@/utils/amazon";
import { useCount, useOnce } from "@/utils/hooks";

import {
  confirm,
  getDefaultFormValues,
  gotoAmazon,
  gotoAmazonChangePayment,
  gotoAmazonCheckout,
  submit,
  scrollToForm,
} from "./helpers";
import styles from "./PointForm.module.scss";
import { PointPlanCompletion } from "./PointPlanCompletion";
import { PointPlanConfirmation } from "./PointPlanConfirmation";
import { PointPlanValues } from "./schema";
import { FormSection } from "../../checkout-form/CheckoutForm/FormSection/FormSection";

const options = [
  {
    title: (
      <>
        {PointProductName[PointProductPlan.lightSet]}
        <br />
        ¥20,000で21,000pt購入
      </>
    ),
    value: PointProductPlan.lightSet,
  },
  {
    title: (
      <>
        {PointProductName[PointProductPlan.annualSet]}
        <br />
        ¥66,000で72,600pt購入
      </>
    ),
    value: PointProductPlan.annualSet,
  },
];

type Phase = "form" | "confirmation" | "completion";

export function PointPlanForm(): React.ReactNode {
  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;
  const pathname = usePathname();

  const searchParams = useSearchParams();
  const [phase, setPhase] = useState<Phase>("form");

  const {
    amazonPayEnable,
    amazonPayCheckoutSessionId,
    amazonPayCheckoutData,
    amazonPayCheckoutError,
  } = useAmazonPay();

  const formContext = useForm<PointPlanValues>({
    defaultValues: getDefaultFormValues(amazonPayEnable, amazonPayCheckoutSessionId),
  });

  const { data: currentPlan } = useGetPointProductSuspense({ name: formContext.watch("planType") });

  const handleChangeAmazonPayPayment = useCallback(async () => {
    await gotoAmazonChangePayment(formContext.getValues(), amazonPayCheckoutSessionId);
  }, [amazonPayCheckoutSessionId, formContext]);

  const handleConfirm = useCallback(async () => {
    startLoading();

    const values = formContext.getValues();
    if (values.paymentData.paymentMethod === PaymentMethod.amazon && !amazonPayEnable) {
      return await gotoAmazon(values, pathname);
    }

    const isConfirmed = await confirm(values, currentPlan.pointProduct);
    if (isConfirmed) setPhase("confirmation");
    scrollToForm();
    endLoading();
  }, [amazonPayEnable, endLoading, pathname, startLoading, currentPlan, formContext]);

  const [[before, after], setPoints] = useState([0, 0]);

  const executeSubmit = useCallback(async () => {
    startLoading();
    const res = await submit(formContext.getValues(), currentPlan.pointProduct);
    if (res) {
      setPhase("completion");
      setPoints([Number(res.before_point), Number(res.after_point)]);
    }
    scrollToForm();
    endLoading();
  }, [endLoading, startLoading, formContext, currentPlan.pointProduct]);

  const handleSubmit = useCallback(async () => {
    // amazonPayの場合はAmazon側で注文確定を実行する
    if (amazonPayEnable) {
      startLoading();
      return await gotoAmazonCheckout(formContext.getValues(), currentPlan.pointProduct, pathname);
    }

    await executeSubmit();
  }, [
    startLoading,
    formContext,
    currentPlan.pointProduct,
    amazonPayEnable,
    executeSubmit,
    pathname,
  ]);

  // リセットボタン
  const handleBack = useCallback(() => {
    setPhase("form");

    if (amazonPayEnable) {
      // 状態リセットするのでrouter.pushは使わない
      window.location.href = pathname;
    }
  }, [amazonPayEnable, pathname]);

  // Amazonから戻ってきたときの処理(エラー時)
  useOnce(async () => {
    if (amazonPayCheckoutError) {
      scrollToForm();
      toast.error(amazonPayCheckoutError);
      await fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
    }
  });

  // Amazonから戻ってきたときの処理
  useOnce(async () => {
    if (amazonPayEnable) {
      scrollToForm();
      if (searchParams.get("confirm") === "true") {
        // 注文確定のボタンを押して、Amazon側で注文確定を実行したあとリダイレクトで戻った場合、注文をバックエンドに送り完了画面に遷移
        await executeSubmit();
      }
    }
  });

  return (
    <LoadingOverlay isLoading={isLoading}>
      {phase === "confirmation" ? (
        <PointPlanConfirmation
          form={formContext.getValues()}
          pointProduct={currentPlan.pointProduct}
          onClickBack={handleBack}
          onClickSubmit={handleSubmit}
        />
      ) : phase === "completion" ? (
        <PointPlanCompletion before={before} after={after} />
      ) : (
        <FormProvider {...formContext}>
          <form className={styles.checkout}>
            <div className="text__center">
              <h2 className="text__xl text__bold text__center text___yellow mg__bottom__m">
                <span className="wsnr">お得なポイントを</span>
                <wbr />
                <span className="wsnr">購入する</span>
              </h2>
            </div>

            <FormSection title="プランの選択">
              <Controller
                name="planType"
                render={({ field: { value, onChange } }) => {
                  return <PanelSelector value={value} onChange={onChange} options={options} />;
                }}
              />
            </FormSection>
            {!currentPlan.canPurchase && (
              <p className="text__m text__bold text__red text__center__pc">
                保有できるポイント上限は100,000ptのため、ご購入いただけません。
              </p>
            )}

            <FormSection
              title="支払い方法"
              subText="すべての取引は安全であり、暗号化されています。"
              action={
                amazonPayEnable && (
                  <ConfigButton onClick={handleChangeAmazonPayPayment}>変更</ConfigButton>
                )
              }
            >
              {amazonPayEnable ? (
                <p className={clsx("text__m", "mg__bottom__s")}>
                  {amazonPayCheckoutData?.paymentPreferences}
                </p>
              ) : (
                <Controller
                  name="paymentData"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <PaymentMethodSelector onChangePaymentData={onChange} paymentData={value} />
                    );
                  }}
                />
              )}
            </FormSection>

            <div className="text__center">
              <Button
                variants={"yellow"}
                rounded
                size={"md"}
                onClick={handleConfirm}
                className="text__m mg__bottom__s"
                type="button"
                disabled={!currentPlan.canPurchase}
              >
                お得なポイントを購入する
                <i className="fas fa-angle-right"></i>
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </LoadingOverlay>
  );
}

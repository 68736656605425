export const PointProductPlan = {
  lightSet: "LIGHT_SET",
  annualSet: "ANNUAL_SET",
} as const;

export type PointProductPlanValue = (typeof PointProductPlan)[keyof typeof PointProductPlan];

export const PointProductName = {
  [PointProductPlan.lightSet]: "ライトプラン",
  [PointProductPlan.annualSet]: "レギュラープラン",
} as const;
